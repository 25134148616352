// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-preview-blogpost-tsx": () => import("./../../../src/pages/preview/blogpost.tsx" /* webpackChunkName: "component---src-pages-preview-blogpost-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/preview/page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

