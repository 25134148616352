module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"classes":false,"events":false,"typekit":{"id":"mxr7ovj"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Elsa - Påverka din reumatoid artrit","short_name":"Elsa","description":"Elsa hjälper dig som har reumatoid artrit.","start_url":"/","lang":"sv","display":"standalone","icon":"src/favicon.png","background_color":"#110d28","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"Elsa - Impact your rheumatoid arthritis","short_name":"Elsa","description":"Elsa helps you with your rheumatoid arthritis."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42f38c90a864b39f507b987d526f85a9"},
    }]
